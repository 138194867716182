<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4 pa-6">
        <v-row>
          <v-col cols="3">
            <v-btn color="green" outlined @click="downloadTemplate" block
              >Download Template</v-btn
            >
          </v-col>
          <v-col cols="3">
            <v-file-input
              show-size
              counter
              multiple
              dense
              outlined
              prepend-icon=""
              prepend-inner-icon="attach_file"
              label="File input"
              @change="handleFileUpload"
            ></v-file-input>
          </v-col>

          <div></div>
          <v-spacer></v-spacer>
          <v-tooltip left color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                class="mr-4"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <p><b>Expected File Format:</b></p>
            <li>Column A header must be named Destination</li>
            <li>Column B header must be named Service</li>
            <li>
              Followed by all weight columns, where each column header is the
              max weight of the bracket
            </li>
            <p>Eg:</p>
            <p>
              Destination | Service | 0.25 | 0.5 | 0.75 | 1 | 5 | 10 | 15 | 20 |
              30 | 40
            </p>
            <p>
              Australia | DDU Courier | 4.10 | 5.43 | 6.44 | 8.23 | 11.55 |
              12.33 | 20.32 | 30.22 | 35.22 | 41.33
            </p>
          </v-tooltip>
        </v-row>
      </v-card>
      <v-card class="pa-4" v-if="rates.length">
        <ExportButtons :headers="ratesHeaders" :data="rates" :title="'formatted_rates'" />
        <v-data-table
          :headers="ratesHeaders"
          :items="rates"
          :items-per-page="5"
        ></v-data-table>
      </v-card>
      <v-card class="pa-4" v-else>
        <v-card-title>input a file to format</v-card-title>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import ExcelMixin from '@/mixins/Excel';
import CommonFunctionsMixin from '@/mixins/BillingCommonFunctions';
import ExportButtons from '@/components/ExportButtons';

export default {
  components: { ExportButtons },
  mixins: [ExcelMixin, CommonFunctionsMixin],
  data() {
    return {
      validForm: true,
      // Title
      title: 'Format Carrier Files To Sorted',
      // Snackbar
      snackbar: false,
      text: '',
      loading: false,

      file: null,
      rates: [],
      ratesHeaders: [],
    };
  },

  created() {},
  methods: {
    async handleFileUpload(ev) {
      const file = ev[0];
      this.file = file;

      if (file) {
        this.extractJson(file)
          .then((jsonSpreadsheet) => {
            const rates = this.extractRatesFromSpreadsheet(jsonSpreadsheet);
            this.ratesHeaders = this.getHeaders(rates);
            this.rates = rates;
          })
          .catch((err) => {
            this.snackbar = true;
            this.text = err;
          });
      }
    },
    getSortedCountryReference(country) {
      // if (!country) return country;
      // const allCapsText = country.toUpperCase();
      // const underscored = allCapsText.replace(/\s+/g, "_");
      // return underscored;

      const mappings = {
        Afghanistan: 'AFGHANISTAN',
        'Aland Islands': 'ALAND ISLANDS',
        Albania: 'ALBANIA',
        Algeria: 'ALGERIA',
        Andorra: 'ANDORRA',
        Angola: 'ANGOLA',
        Anguilla: 'ANGUILLA',
        'Antigua and Barbuda': 'ANTIGUA_AND_BARBUDA',
        Argentina: 'ARGENTINA',
        Armenia: 'ARMENIA',
        Aruba: 'ARUBA',
        Australia: 'AUSTRALIA',
        Austria: 'AUSTRIA',
        Azerbaijan: 'AZERBAIJAN',
        Bahamas: 'BAHAMAS',
        Bahrain: 'BAHRAIN',
        Bangladesh: 'BANGLADESH',
        Barbados: 'BARBADOS',
        Belarus: 'BELARUS',
        Belgium: 'BELGIUM',
        Belize: 'BELIZE',
        Benin: 'BENIN',
        Bermuda: 'BERMUDA',
        Bhutan: 'BHUTAN',
        Bolivia: 'BOLIVIA',
        'Bonaire, Sint Eustatius and Saba': 'BONAIRE_AND_ST EUSTATIUS',
        'Bosnia and Herzegovina': 'BOSNIA_AND_HERZEGOVINA',
        Botswana: 'BOTSWANA',
        Brazil: 'BRAZIL',
        'Brunei Darussalam': 'BRUNEI',
        Bulgaria: 'BULGARIA',
        'Burkina Faso': 'BURKINA_FASO',
        Burundi: 'BURUNDI',
        'Cabo Verde': 'n/a',
        Cambodia: 'CAMBODIA',
        Cameroon: 'CAMEROON',
        Canada: 'CANADA',
        'Cayman Islands': 'CAYMAN_ISLANDS',
        'Central African Republic': 'CENTRAL_AFRICAN_REPUBLIC',
        Chad: 'CHAD',
        Chile: 'CHILE',
        China: 'CHINA',
        Colombia: 'COLOMBIA',
        Comoros: 'COMOROS',
        Congo: 'CONGO,_DEMOCRATIC_REPUBLIC',
        'Congo (Democratic Republic of the)': 'CONGO_(BRAZZAVILLE)',
        'Cook Islands': 'COOK_ISLANDS',
        'Costa Rica': 'COSTA_RICA',
        Croatia: 'CROATIA',
        Cuba: 'CUBA',
        Curacao: 'CURACAO',
        Cyprus: 'CYPRUS',
        Czechia: 'CZECH_REPUBLIC',
        Denmark: 'DENMARK',
        Djibouti: 'DJIBOUTI',
        Dominica: 'DOMINICA',
        'Dominican Republic': 'DOMINICAN_REPUBLIC',
        Ecuador: 'ECUADOR',
        Egypt: 'EGYPT',
        'El Salvador': 'EL_SALVADOR',
        'Equatorial Guinea': 'EQUATORIAL_GUINEA',
        Eritrea: 'ERITREA',
        Estonia: 'ESTONIA',
        Ethiopia: 'ETHIOPIA',
        'Falkland Islands (Malvinas)': 'FALKLAND_ISLANDS',
        'Faroe Islands': 'FAROE_ISLANDS',
        Fiji: 'FIJI',
        Finland: 'FINLAND',
        France: 'FRANCE',
        'French Guiana': 'FRENCH_GUIANA',
        'French Polynesia': 'FRENCH_POLYNESIA',
        Gabon: 'GABON',
        Gambia: 'GAMBIA',
        Georgia: 'GEORGIA',
        Germany: 'GERMANY',
        Ghana: 'GHANA',
        Gibraltar: 'GIBRALTAR',
        Greece: 'GREECE',
        Greenland: 'GREENLAND',
        Grenada: 'GRENADA',
        Guadeloupe: 'GUADELOUPE',
        Guam: 'GUAM',
        Guatemala: 'GUATEMALA',
        Guinea: 'GUINEA',
        'Guinea-Bissau': 'GUINEA_BISSAU',
        Guyana: 'GUYANA',
        Haiti: 'HAITI',
        Honduras: 'HONDURAS',
        'Hong Kong SAR, China': 'HONG_KONG',
        Hungary: 'HUNGARY',
        Iceland: 'ICELAND',
        India: 'INDIA',
        Indonesia: 'INDONESIA',
        Iran: 'IRAN',
        Iraq: 'IRAQ',
        Ireland: 'IRELAND',
        Israel: 'ISRAEL',
        Italy: 'ITALY',
        'Ivory Coast': "COTE_D'IVOIRE_(IVORY_COAST)",
        Jamaica: 'JAMAICA',
        Japan: 'JAPAN',
        Jordan: 'JORDAN',
        Kazakhstan: 'KAZAKHSTAN',
        Kenya: 'KENYA',
        Kiribati: 'KIRIBATI',
        Kosovo: 'KOSOVO',
        Kuwait: 'KUWAIT',
        Kyrgyzstan: 'KYRGYZSTAN',
        "Lao People's Democratic Republic": 'LAOS',
        Latvia: 'LATVIA',
        Lebanon: 'LEBANON',
        Lesotho: 'LESOTHO',
        Liberia: 'LIBERIA',
        Liechtenstein: 'LIECHTENSTEIN',
        Lithuania: 'LITHUANIA',
        Luxembourg: 'LUXEMBOURG',
        'Macau SAR, China': 'MACAU',
        Madagascar: 'MADAGASCAR',
        Malawi: 'MALAWI',
        Malaysia: 'MALAYSIA',
        Maldives: 'MALDIVES',
        Mali: 'MALI',
        Malta: 'MALTA',
        'Marshall Islands': 'MARSHALL_ISLANDS',
        Martinique: 'MARTINIQUE',
        Mauritania: 'MAURITANIA',
        Mauritius: 'MAURITIUS',
        Mayotte: 'MAYOTTE',
        Mexico: 'MEXICO',
        Moldova: 'MOLDOVA',
        Monaco: 'MONACO',
        Mongolia: 'MONGOLIA',
        Montenegro: 'MONTENEGRO',
        Montserrat: 'MONTSERRAT',
        Morocco: 'MOROCCO',
        Mozambique: 'MOZAMBIQUE',
        Myanmar: 'MYANMAR_(BURMA)',
        Namibia: 'NAMIBIA',
        Nauru: 'NAURU',
        Nepal: 'NEPAL',
        Netherlands: 'NETHERLANDS',
        'New Caledonia': 'NEW_CALEDONIA',
        'New Zealand': 'NEW_ZEALAND',
        Nicaragua: 'NICARAGUA',
        Niger: 'NIGER',
        Nigeria: 'NIGERIA',
        'North Korea': 'NORTH_KOREA',
        'North Macedonia': 'MACEDONIA',
        'Northern Mariana Islands': 'NORTHERN_MARIANA_ISLANDS',
        Norway: 'NORWAY',
        Oman: 'OMAN',
        Pakistan: 'PAKISTAN',
        Palau: 'PALAU',
        Palestine: 'PALESTINE',
        Panama: 'PANAMA',
        'Papua New Guinea': 'PAPUA_NEW_GUINEA',
        Paraguay: 'PARAGUAY',
        Peru: 'PERU',
        Philippines: 'PHILIPPINES',
        Pitcairn: 'PITCAIRN',
        Poland: 'POLAND',
        Portugal: 'PORTUGAL',
        'Portugal - Portuguese Islands': 'n/a',
        'Puerto Rico': 'PUERTO_RICO',
        Qatar: 'QATAR',
        Reunion: 'REUNION',
        Romania: 'ROMANIA',
        Rwanda: 'RWANDA',
        'Saint Kitts and Nevis': 'SAINT_KITTS_AND_NEVIS',
        'Saint Lucia': 'SAINT_LUCIA',
        'Saint Pierre and Miquelon': 'SAINT_PIERRE_AND_MIQUELON',
        'Saint Vincent and the Grenadines': 'SAINT_VINCENT_AND_THE_GRENADINES',
        Samoa: 'SAMOA',
        'San Marino': 'SAN_MARINO',
        'Saudi Arabia': 'SAUDI_ARABIA',
        Senegal: 'SENEGAL',
        Serbia: 'SERBIA',
        Seychelles: 'SEYCHELLES',
        'Sierra Leone': 'SIERRA_LEONE',
        Singapore: 'SINGAPORE',
        Slovakia: 'SLOVAKIA',
        Slovenia: 'SLOVENIA',
        'Solomon Islands': 'SOLOMON_ISLANDS',
        Somalia: 'SOMALIA',
        'South Africa': 'SOUTH_AFRICA',
        'South Korea': 'SOUTH_KOREA',
        'South Sudan': 'SOUTH_SUDAN',
        Spain: 'SPAIN',
        'Spain - Balearic Islands': 'n/a',
        'Spain - Canary Islands': 'n/a',
        'Sri Lanka': 'SRI LANKA',
        Sudan: 'SUDAN',
        Suriname: 'SURINAME',
        Swaziland: 'SWAZILAND',
        Sweden: 'SWEDEN',
        Switzerland: 'SWITZERLAND',
        'Taiwan, China': 'TAIWAN',
        Tajikistan: 'TAJIKISTAN',
        Tanzania: 'TANZANIA',
        Thailand: 'THAILAND',
        'Timor-Leste': 'n/a',
        Togo: 'TOGO',
        Tonga: 'TONGA',
        'Trinidad and Tobago': 'TRINIDAD_AND_TOBAGO',
        Tunisia: 'TUNISIA',
        Turkey: 'TURKEY',
        Turkmenistan: 'TURKMENISTAN',
        'Turks and Caicos Islands': 'TURKS_AND_CAICOS_ISLANDS',
        Tuvalu: 'TUVALU',
        Uganda: 'UGANDA',
        Ukraine: 'UKRAINE',
        'United Arab Emirates': 'UNITED_ARAB_EMIRATES',
        'United States': 'USA',
        'United States - Mainland': 'n/a',
        'United States - Rest Of': 'n/a',
        Uruguay: 'URUGUAY',
        Uzbekistan: 'UZBEKISTAN',
        Vanuatu: 'VANUATU',
        Venezuela: 'VENEZUELA',
        'Viet Nam': 'VIETNAM',
        'Virgin Islands (British)': 'VIRGIN_ISLANDS_GB',
        'Virgin Islands (U.S.)': 'VIRGIN_ISLANDS_US',
        'Wallis and Futuna': 'WALLIS_AND_FUTUNA',
        Yemen: 'YEMEN',
        Zambia: 'ZAMBIA',
        Zimbabwe: 'ZIMBABWE',
      };
      let mapped = mappings[country]

      return mapped ? mapped : country

    },
    extractRatesFromSpreadsheet(spreadsheet) {
      let parsed = [];
      for (const row of spreadsheet) {
        let destination = row.Destination;
        let service = row.Service;
        let headers = Object.keys(row);
        for (const header of headers) {
          const ignoreHeaders = ['Destination', 'Service'];
          if (ignoreHeaders.includes(header)) continue;
          //check if its a valid weight reference
          const parsedWeight = parseFloat(header);
          if (!parsedWeight) continue;
          const parsedRow = {
            sorted_country: this.getSortedCountryReference(destination),
            service: service,
            weight: header,
            cost: row[header],
            original_country_reference: destination,
          };
          parsed.push(parsedRow);
        }
      }
      parsed.sort((a, b) => {
        // Compare Destination first
        if (a.sorted_country < b.sorted_country) return -1;
        if (a.sorted_country > b.sorted_country) return 1;

        // If Destination is equal, compare Service
        if (a.service < b.service) return -1;
        if (a.service > b.service) return 1;

        const weightA = parseFloat(a.weight);
        const weightB = parseFloat(b.weight);
        if (!weightA) return 1;
        if (!weightB) return -1;
        if (weightA < weightB) return -1;
        if (weightA > weightB) return 1;

        return 0;
      });

      return parsed;
    },
    downloadTemplate() {
      let template = {
        Destination: 'Austria',
        Service: 'DDP Courier',
        0.25: 4.82,
        '0.50': 4.82,
        0.75: 4.82,
        '1.00': 5.1,
        1.25: 5.23,
        '1.50': 5.53,
        1.75: 5.64,
        '2.00': 7.21,
        '2.50': 7.23,
        '3.00': 7.43,
        '3.50': 8.13,
        '4.00': 9.23,
        '4.50': 10.11,
        '5.00': 11.32,
        '5.50': 15.23,
        '6.00': 26.23,
        '6.50': 28.32,
        '7.00': 30.12,
        '7.50': 34.83,
        '8.00': 36.2,
        '8.50': 37.57,
        '9.00': 38.95,
        '9.50': 40.32,
        '10.00': 41.7,
        '15.00': 55.44,
        '20.00': 69.17,
        '25.00': 91.73,
        '30.00': 108.83,
      };
      this.generateTemplate(template, 'rates_from_carrier');
    },
  },
};
</script>
<style></style>
